import React from "react"
import { Button, Divider } from "antd"
import { Link } from "react-router-dom"

import styles from "./index.module.scss"
import Logo from "../../components/logo/logo"

const NotFoundPage: React.FC = () => {
  return (
    <div className={styles.notFound}>
      <Logo />
      <h1 className={styles.title}>
        Page not found 😢
      </h1>
      <small>
        The page you are looking for does not exist.
      </small>
      <Divider />
      <Link to="/">
        <Button
          size="large"
          type="primary"
          block={true}
        >
          Back to the main page
        </Button>
      </Link>
      <small className={styles.footnote}>
        If you went to this page by clicking a link, please contact our support team at&nbsp;
        <a href="mailto:support@roberty.app">
          support@roberty.app
        </a>
      </small>
    </div>
  )
}

export default NotFoundPage