import moment from "moment"
import I18nMessages from "../messages"

const enUS: I18nMessages = {
  "app.title": "Roberty",
  "app.copyright": `Copyright © ${new Date().getFullYear()} Roberty Automation. All rights reserved.`,
  "app.connectionError": "There was an error while connecting with our servers. Please try again later 🙁",
  "common.back": "Back",
  "common.cancel": "Cancel",
  "common.close": "Close",
  "common.confirm": "Confirm",
  "common.delete": "Delete",
  "common.edit": "Edit",
  "common.save": "Save",
  "common.search": "Search",
  "common.yes": "Yes",
  "common.no": "No",
  "common.next": "Next",
  "common.previous": "Previous",
  "common.loading": "Loading...",
  "common.soon": "Soon",
  "menu.home": "Home",
  "menu.dashboard": "Dashboard",
  "menu.workspaces": "Workspaces",
  "menu.realtimeMonitor": "Realtime Monitor",
  "menu.notifications": "Notifications",
  "menu.billing": "Billing",
  "menu.support": "Support",
  "menu.settings": "Settings",
  "menu.swapSubtitle": "Partner's management",
  "realtime.title": "Realtime Monitor",
  "realtime.tvMode.enter": "Enter TV mode",
  "realtime.tvMode.exit": "Exit TV mode",
  "realtime.running.title": "Running now",
  "realtime.running.empty": "No robots are running right now",
  "realtime.running.inProgress": "In progress",
  "realtime.running.takingLonger": "Taking longer",
  "realtime.running.stop": "Stop",
  "realtime.running.averageDuration": "Average duration",
  "realtime.next.title": "Up next",
  "realtime.next.empty": "No robots are scheduled to run on the next 24 hours",
  "realtime.next.startingIn": "Starting in",
  "realtime.next.startingSoon": "Starting soon",
  "realtime.next.delayed": "Delayed",
  "realtime.next.nextRunDate": (nextRunDate: Date): string => `Starting ${moment(nextRunDate).isSame(moment().add(1, "day"), "day") ? "tomorrow at" : "at"} ${moment(nextRunDate).format("HH:mm")}`,
  "realtime.errors.title": "Errors",
  "realtime.errors.empty": "No errors found",
  "realtime.errors.robot.title": "Robot",
  "realtime.errors.errorInfo.title": "Error info",
  "realtime.errors.actions.title": "Actions",
  "realtime.errors.type": "Type",
  "realtime.errors.duration": "Duration",
  "realtime.errors.failedAt": "Failed",
  "realtime.errors.lastRunAt": "Last run was",
  "realtime.errors.outputButton": "Output",
  "realtime.errors.screenshotButton": "Screenshot",
  "realtime.errors.markAsSolved": (amount?: number) => `Mark${amount ? ` ${amount}` : ""} as solved`,
  "realtime.errors.markAsSolved.confirm": (amount: number) => `Are you sure you want to mark ${amount} as solved?`,
}

export default enUS